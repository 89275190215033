export const getAddress = (user = {}) => {
  const { line1, city, zip, state, country } = user?.address || {};
  let address = line1 || '';
  !!address.length && city && (address = address.concat(', '));
  city && (address = address.concat(city));
  !!address.length && zip && (address = address.concat(', '));
  zip && (address = address.concat(zip));
  !!address.length && state && (address = address.concat(', '));
  state && (address = address.concat(state));
  !!address.length && country && (address = address.concat(', '));
  country && (address = address.concat(country));
  !address.length && (address = 'No address registered');
  return address;
};

export const loggedIn = () => {
  const { token } = JSON.parse(localStorage.getItem('data')) || {};
  return token;
};

export const getUserInfo = () => {
  return JSON.parse(localStorage.getItem('data'))?.user;
};

export const defaultAvatar = require('@/assets/billfish-logo-new.png');

export const setRequestedUserData = input => {
  const data = window.structuredClone(input);
  const { angler, captain, firstMate, secondMate } = data || {};
  return {
    ...data,
    angler: getRequestedUserData(angler),
    captain: getRequestedUserData(captain),
    firstMate: getRequestedUserData(firstMate),
    secondMate: getRequestedUserData(secondMate)
  };
};

export const getRequestedUserData = user => {
  return {
    id: user?._id || user?.id,
    legacyId: user?.legacyId,
    firstName: user?.firstName,
    lastName: user?.lastName,
    middleName: user?.middleName,
    email: user?.email,
    memberId: user?.memberId,
    memberExpiration:
      user?.memberExpiration || user?.membership?.expirationDate,
    address: user?.address?.line1,
    city: user?.address?.city,
    state: user?.address?.state,
    zip: user?.address?.zip,
    country: user?.address?.country
  };
};
