import { loggedIn } from '../helpers/handler';

export default [
  {
    path: '*',
    redirect: '/searches',
    component: () => import('@/layouts/full-layout/Layout'),
    children: [
      {
        name: 'searches',
        path: '/searches',
        component: () => import('@/views/search/Search')
      },
      {
        name: 'manage-users',
        path: '/users',
        component: () => import('@/views/users/ManageUsers')
      },
      {
        name: 'edit-items',
        path: '/edit',
        component: () => import('@/views/search/EditItems')
      },
      {
        name: 'analytics',
        path: '/analytics',
        component: () => import('@/views/analytics/index')
      },
      {
        name: 'certificates',
        path: '/certificates',
        component: () => import('@/views/certificates/index')
      },
      {
        name: 'confirmations',
        path: '/confirmations',
        component: () => import('@/views/confirmations/index')
      },
      {
        name: 'issuance',
        path: '/issuance',
        component: () => import('@/views/issuance/index')
      },
      {
        name: 'competition',
        path: '/competition',
        component: () => import('@/views/competition/index')
      },
      {
        name: 'duplicates',
        path: '/duplicates',
        component: () => import('@/views/duplicates/index')
      },
      {
        name: 'trophies',
        path: '/trophies',
        component: () => import('@/views/trophies/index')
      },
      {
        name: 'membership',
        path: '/membership',
        component: () => import('@/views/membership/index')
      },
      {
        name: 'activities',
        path: '/activities',
        component: () => import('@/views/analytics/ActivitiesReport')
      },
      {
        name: 'email-templates',
        path: '/email-templates',
        component: () => import('@/views/email-templates/index')
      }
    ],
    beforeEnter(to, from, next) {
      const token = loggedIn();
      !token ? next({ path: '/login' }) : next();
    }
  },
  {
    path: '',
    redirect: '/login',
    component: () => import('@/layouts/blank-layout/BlankLayout'),
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('@/views/authentication/BoxedLogin')
      },
      {
        name: 'forgot-password',
        path: '/forgot-password',
        component: () => import('@/views/authentication/ForgotPassword.vue')
      },
      {
        name: 'reset-password',
        path: '/reset-password',
        component: () => import('@/views/authentication/ResetPassword.vue'),
        beforeEnter(to, from, next) {
          const { token, email } = to?.query || {};
          console.log({ token, email });
          token && email ? next() : next({ path: '/login' });
        }
      },
      {
        name: 'privacy',
        path: '/privacy',
        component: () => import('@/views/authentication/Privacy.vue')
      }
    ],
    beforeEnter(to, from, next) {
      if (to?.name === 'login') {
        const token = loggedIn();
        token ? next({ path: '/searches' }) : next();
      } else {
        next();
      }
    }
  }
];
