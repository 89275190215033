import { handleCall } from '../../helpers/handler';
class QueryModule {
  constructor(httpClientFactory) {
    const queryAPI = httpClientFactory.clients.query;

    this._store = {
      state: {},
      actions: {
        getStates() {
          return handleCall('GET', queryAPI, 'states')?.data;
        },
        getTags({ state }, data) {
          const queryParams = `userId=${data.legacyId}&page=${data.page}&limit=${data.limit}`;
          return handleCall('GET', queryAPI, `tags?${queryParams}`);
        },
        getRecaptures({ state }, data) {
          const queryParams = `userId=${data.legacyId}&page=${data.page}&limit=${data.limit}`;
          return handleCall('GET', queryAPI, `recaptures?${queryParams}`);
        },
        getReleases({ state }, data) {
          const queryParams = `userId=${data.legacyId}&page=${data.page}&limit=${data.limit}`;
          return handleCall('GET', queryAPI, `releases?${queryParams}`);
        },
        getItems({ state }, params) {
          const { page, limit, name, type } = params;
          let queryParams = `page=${page}&limit=${limit}`;
          name && (queryParams = queryParams.concat(`&name=${name}`));
          type && (queryParams = queryParams.concat(`&type=${type}`));
          return handleCall('GET', queryAPI, `${params.item}?${queryParams}`);
        },
        async getFilterResults({ state }, params) {
          let payload = {};

          for (let param in params) {
            let value = params[param];

            if (!value) {
              continue;
            }

            if (param === 'sort') {
              param = 'sorting';
            }

            if (param === 'species' || param === 'boat') {
              if (param === 'species') {
                param = 'specie';
              }

              value = value.name;
            }

            if (param === 'quadrant') {
              const { NE, SW } = value || {};
              const nullValue =
                Object.values(NE.latitude).some((v) => !v) &&
                Object.values(NE.longitude).some((v) => !v) &&
                Object.values(SW.latitude).some((v) => !v) &&
                Object.values(SW.longitude).some((v) => !v);

              if (nullValue) {
                continue;
              }

              value = JSON.stringify(value);
            }

            if (param === 'polygon') {
              if (!value?.length) {
                continue;
              }

              value = JSON.stringify(value);
            }

            if (param === 'tag') {
              param = 'tagNumber';
            }

            if (param === 'user' || param === 'enteredBy') {
              const { _id, id } = value || {};
              value = _id || id;
            }

            if (param === 'catchDate' || param === 'enteredByDate') {
              const [start, end] = value || [];

              if (!start || !end) {
                continue;
              }
            }

            payload[param] = value;
          }

          return handleCall('POST', queryAPI, `/searches`, payload);
        },
        getCertifications({ state }, params) {
          const {
            date,
            role,
            page,
            limit,
            date_type,
            export: exportable,
            format
          } = params;
          let query = `page=${page}&limit=${limit}&role=${role}&date_type=${date_type}`;
          exportable && (query = query.concat(`&export=${exportable}`));
          format && (query = query.concat(`&format=${format}`));
          date &&
            date[0] &&
            date[1] &&
            (query = query.concat(
              `&start_date=${date[0]}&end_date=${date[1]}`
            ));
          const path = `certificates/old-format?${query}`;
          return handleCall('GET', queryAPI, path);
        },
        getConfirmations({ state }, params) {
          const {
            date,
            page,
            limit,
            date_type,
            export: exportable,
            format
          } = params;
          let query = `page=${page}&limit=${limit}&date_type=${date_type}`;
          exportable && (query = query.concat(`&export=${exportable}`));
          format && (query = query.concat(`&format=${format}`));
          date &&
            date[0] &&
            date[1] &&
            (query = query.concat(
              `&start_date=${date[0]}&end_date=${date[1]}`
            ));
          const path = `confirmations/old-format?${query}`;
          return handleCall('GET', queryAPI, path);
        },
        getIssuance({ state }, params) {
          const { lost, id, limit, page, number } = params;
          let query = `tags-issue?page=${page}&limit=${limit}&lost=${lost}`;
          number && (query = query.concat(`&number=${number}`));
          id && (query = query.concat(`&userId=${id}`));
          return handleCall('GET', queryAPI, query);
        },
        getIssue({ state }, params) {
          const path = `tags-issue/${params.id}`;
          return handleCall('GET', queryAPI, path);
        },
        getCompetition({ state }, params = {}) {
          const { acronym, year } = params;
          let query = 'competitions';
          (acronym || year) && (query = query.concat('?'));
          acronym && (query = query.concat(`acronym=${acronym}`));
          acronym && year && (query = query.concat('&'));
          year && (query = query.concat(`year=${year}`));
          return handleCall('GET', queryAPI, query);
        },
        setDisqualify({ state }, params) {
          const { email, enable, body } = params;
          const path = `competitions/disqualify?email=${email}&enable=${enable}`;
          return handleCall('POST', queryAPI, path, body);
        },
        getSavedCompetition({ state }, params = {}) {
          const { acronym, year } = params;
          const query = `competitions/winners?acronym=${acronym}&year=${year}`;
          return handleCall('GET', queryAPI, query);
        },
        saveCompetition({ state }, params = {}) {
          const { acronym, year, email, body, approve } = params;
          let query = `competitions/winners?acronym=${acronym}&year=${year}&email=${email}`;
          approve && (query = query.concat(`approve=${approve}`));
          return handleCall('POST', queryAPI, query, body);
        },
        setApproval({ state }, params = {}) {
          const { acronym, year, email } = params;
          let query = `competitions/winners/approve?acronym=${acronym}&year=${year}`;
          return handleCall('POST', queryAPI, query, { email });
        },
        getICCAT({ state }, params = {}) {
          const { year } = params;
          const query = `icatts?year=${year}`;
          return handleCall('GET', queryAPI, query);
        },
        getLocation({ state }, params = {}) {
          const { page, limit, ...body } = params;
          const queryParams = `page=${page}&limit=${limit}`;
          const query = `getLocations?${queryParams}`;
          return handleCall('POST', queryAPI, query, body);
        },
        getCrewLocations({ state }, params = {}) {
          return handleCall('POST', queryAPI, 'crew-locations', params);
        },
        getStatistics({ state }) {
          return handleCall('GET', queryAPI, `statistics`);
        },
        getTrophies({ state }, params = {}) {
          const {
            year,
            trophy,
            category,
            format,
            userId,
            role,
            location,
            date,
            youth,
            sort,
            order
          } = params;
          let queryParams = `page=${params.page}&limit=${params.limit}`;
          if (sort) {
            queryParams = queryParams.concat(`&sort=${sort}`);
          }
          if (order) {
            queryParams = queryParams.concat(`&order=${order}`);
          }
          if (category) {
            queryParams = queryParams.concat(`&category=${category}`);
          }
          if (trophy) {
            queryParams = queryParams.concat(`&trophy=${trophy}`);
          }
          if (year) {
            queryParams = queryParams.concat(`&year=${year}`);
          }
          if (format) {
            queryParams = queryParams.concat(`&format=${format}`);
          }
          if (userId) {
            queryParams = queryParams.concat(`&userId=${userId}`);
          }
          if (role) {
            queryParams = queryParams.concat(`&role=${role}`);
          }
          if (location) {
            queryParams = queryParams.concat(`&location=${location}`);
          }
          if (date && date[0] && date[1]) {
            queryParams = queryParams.concat(
              `&start_date=${date[0]}&end_date=${date[1]}`
            );
          }
          if (youth) {
            queryParams = queryParams.concat(`&youth=${true}`);
          }
          const query = `trophies/crew?${queryParams}`;
          return handleCall('GET', queryAPI, query);
        },
        getTrophiesLocations({ state }, params = {}) {
          const query = `trophies/locations?pattern=${params.pattern}`;
          return handleCall('GET', queryAPI, query);
        },
        recalculateMilestonesByUser({ state }, params = {}) {
          const query = `trophies/recalculate?userId=${params.userId}`;
          return handleCall('GET', queryAPI, query);
        },
        getAffectedMemberships({}, params = {}) {
          const { page, limit } = params;
          const queryParams = `page=${page}&limit=${limit}`;
          const query = `membership/find?${queryParams}`;
          return handleCall('GET', queryAPI, query);
        },
        getDuplicatedMemberships({}, params = {}) {
          const query = `membership/retrieve?memberId=${params.memberId}`;
          return handleCall('GET', queryAPI, query);
        },
        cleanMembership({}, params = {}) {
          const { userId, cleanedBy, removed } = params;
          const queryParams = `userId=${userId}&cleanedBy=${cleanedBy}`;
          const query = `membership/clean?${queryParams}`;
          return handleCall('POST', queryAPI, query, removed);
        },
        getActivitiesReport({}, params = {}) {
          const { email, startDate, endDate } = params;
          const query = `report?email=${email}&start_date=${startDate}&end_date=${endDate}`;
          return handleCall('GET', queryAPI, query);
        },
        getTemplate() {
          return handleCall('GET', queryAPI, '/file');
        }
      },
      mutations: {},
      namespaced: true
    };
  }

  get store() {
    return this._store;
  }
}

export default QueryModule;
