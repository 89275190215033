import Vue from 'vue';
import routes from './routes';
import Router from 'vue-router';
import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
import goTo from 'vuetify/es5/services/goto';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
     // This is for the scroll top when click on any router link
     scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
    routes: routes
});

// router guards
router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
});

router.afterEach((to, from) => {
    // ...
    NProgress.done();
});

export default router;