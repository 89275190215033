import { handleCall } from '../../helpers/handler';
class AnalyticModule {
  constructor(httpClientFactory) {
    const analyticAPI = httpClientFactory.clients.analytic;
    const queryAPI = httpClientFactory.clients.query;

    this._store = {
      state: {},
      getters: {},
      mutations: {},
      actions: {
        getFishCondition({ state }, params) {
          const { specie, year } = params || {};
          const source = String(params.source).toLowerCase();
          const path = `conditions?specie=${specie}&year=${year}&source=${source}&page=1&limit=10`;
          return handleCall('GET', analyticAPI, path);
        },
        getYearSummary({ state }, params) {
          const source = String(params.source).toLowerCase();
          let queryParams = `source=${source}`;
          if (params?.range) {
            queryParams = queryParams.concat(
              `&start_date=${params.range[0]}&end_date=${params.range[1]}`
            );
          } else {
            queryParams = queryParams.concat(`year=${params.year}`);
          }
          const path = `summaries?${queryParams}`;
          return handleCall('GET', analyticAPI, path);
        },
        getEvents({ state }, params) {
          const { specie, year, ocean, groupBy } = params;
          const event = String(params.event).toLowerCase();
          const source = String(params.source).toLowerCase();
          const query = `specie=${specie}&year=${year}&ocean=${ocean}&groupBy=${groupBy}&event=${event}&source=${source}`;
          return handleCall('GET', analyticAPI, `events?${query}`);
        },
        getFishStatistic({ state }, params) {
          const { specie, event, date, ocean, groupBy, export: exp } = params;
          const source = String(params.source).toLowerCase();
          let query = `groupBy=${groupBy}&source=${source}`;
          event &&
            (query = query.concat(`&event=${String(event).toLowerCase()}`));
          specie && (query = query.concat(`&specie=${specie}`));
          ocean && (query = query.concat(`&ocean=${ocean}`));
          date.every(Boolean) && (query = query.concat(`&date=${date}`));
          exp && (query = query.concat(`&export=${exp}`));
          return handleCall('GET', analyticAPI, `fish?${query}`);
        },
        getRecapturesCompleted({ state }, params) {
          const {
            page,
            limit,
            specie,
            number,
            priorNumber,
            date,
            sort,
            order,
            export: exportable,
            format
          } = params || {};
          const source = String(params.source).toLowerCase();
          let query = `page=${page}&limit=${limit}&source=${source}`;
          specie && (query = query.concat(`&specie=${specie}`));
          number && (query = query.concat(`&number=${number}`));
          priorNumber && (query = query.concat(`&priorNumber=${priorNumber}`));
          sort && (query = query.concat(`&sorting=${sort}`));
          order && (query = query.concat(`&order=${order}`));
          exportable && (query = query.concat(`&export=${exportable}`));
          format && (query = query.concat(`&format=${format}`));
          params?.originalAngler?._id &&
            (query = query.concat(
              `&originalAngler=${params.originalAngler._id}`
            ));
          params?.recaptureAngler?._id &&
            (query = query.concat(
              `&recaptureAngler=${params.recaptureAngler._id}`
            ));
          params?.originalCaptain?._id &&
            (query = query.concat(
              `&originalCaptain=${params.originalCaptain._id}`
            ));
          params?.recaptureCaptain?._id &&
            (query = query.concat(
              `&recaptureCaptain=${params.recaptureCaptain._id}`
            ));
          const quadrant = params?.location;
          const nullValue =
            Object.values(quadrant?.NE?.latitude || {}).some((v) => !v) &&
            Object.values(quadrant?.NE?.longitude || {}).some((v) => !v) &&
            Object.values(quadrant?.SW?.latitude || {}).some((v) => !v) &&
            Object.values(quadrant?.SW?.longitude || {}).some((v) => !v);
          quadrant &&
            !nullValue &&
            (query = query.concat(`&quadrant=${JSON.stringify(quadrant)}`));
          date &&
            date[0] &&
            date[1] &&
            (query = query.concat(
              `&start_date=${date[0]}&end_date=${date[1]}`
            ));
          return handleCall('GET', analyticAPI, `recaptures?${query}`);
        },
        getBoardReports({ state }, params) {
          const source = String(params.source).toLowerCase();
          let query = `year=${params.year}&source=${source}`;
          params.export && (query = query.concat(`&export=${params.export}`));
          return handleCall('GET', queryAPI, `statistics/board?${query}`);
        }
      },
      namespaced: true
    };
  }
  get store() {
    return this._store;
  }
}

export default AnalyticModule;
