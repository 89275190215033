export const DETERMINATIONS = ['ESTIMATED', 'MEASURED', 'UNKNOWN'];

export const WEIGHT_UNITS = {
  KG: 'KILOGRAMS (KG)',
  LBS: 'POUNDS (LB)',
  UNKNOWN: 'UNKNOWN'
};

export const WEIGHT_TYPES = [
  'DRESSED',
  // 'ESTIMATED',
  'ROUND',
  'WHOLE',
  'UNKNOWN'
];

export const LENGTH_UNITS = {
  IN: 'INCHES (IN)',
  FEET: 'FEET (FT)',
  CM: 'CENTIMETERS (CM)',
  M: 'METERS (M)',
  UNKNOWN: 'UNKNOWN'
};

export const LENGTH_TYPES = [
  // 'ESTIMATED',
  'EYEFORK',
  'FORK LENGTH',
  'LOWER JAW FORK LENGTH',
  'TOTAL LENGTH',
  'UNKNOWN'
];

export const LATITUDE_HEMISPHERE = ['N', 'S'];

export const LONGITUDE_HEMISPHERE = ['E', 'W'];

export const GENDER_OPTIONS = ['Male', 'Female', 'NA'];

export const getEventType = (type) => {
  switch (type) {
    case 'R':
      return 'RELEASE';

    case 'C':
      return 'RECAPTURE';

    case 'J':
      return 'JUVENILE';

    case 'T':
    default:
      return 'TAG';
  }
};

export const NOT_SPECIFIED = 'NOT SPECIFIED';

export const LAYERS = [
  'Streets',
  'Topographic',
  'NationalGeographic',
  'Oceans',
  'Gray',
  'DarkGray',
  'Imagery',
  'ImageryClarity',
  'ImageryFirefly',
  'ShadedRelief',
  'Terrain',
  'Physical'
];

export const WATER_SURFACES = [
  'Bay',
  'Canal',
  'Channel',
  'Cove',
  'Delta',
  'Estuary',
  'Fjord',
  'Gulf',
  'Lagoon',
  'Ocean',
  'Reef',
  'Sea',
  'Strait'
];

const FISH_MAX_FEET_LENGTH = {
  BK: 15.5,
  BM: 12,
  SF: 11.5,
  SP: 8,
  ST: 12,
  WM: 0,
  SW: 15,
  BLT: 15,
  YFT: 8,
  ALB: 5
};

const FISH_MAX_LBS_WEIGHT = {
  BK: 1700,
  BM: 2000,
  SF: 230,
  SP: 120,
  ST: 500,
  WM: 0,
  SW: 1200,
  BLT: 2000,
  YFT: 450,
  ALB: 150
};

const meters2feet = (meters) => meters * 3.28084;
const inches2feet = (inches) => inches / 12;
const cm2feet = (cm) => (cm / 100) * 3.28084;
const kg2lbs = (kg) => kg / 0.45359237;

const feet2meters = (feet) => Math.floor(feet / 3.28084);
const feet2inches = (feet) => Math.floor(feet * 12);
const feet2cm = (feet) => Math.floor((feet / 3.28084) * 100);
const lbs2kg = (lbs) => Math.floor(lbs * 0.45359237);

export const getLengthLimitRules = (fish, value, unit) => {
  const maxLength = FISH_MAX_FEET_LENGTH[fish] || 0;

  if (!maxLength || !value) {
    return [];
  }

  switch (unit) {
    case LENGTH_UNITS.IN: {
      const converted = inches2feet(value);
      const convertedMaxLength = feet2inches(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`
      ];
    }

    case LENGTH_UNITS.FEET: {
      return [(v) => !v || value < maxLength || `Max: ${maxLength} ${unit}`];
    }

    case LENGTH_UNITS.M: {
      const converted = meters2feet(value);
      const convertedMaxLength = feet2meters(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`
      ];
    }

    case LENGTH_UNITS.CM: {
      const converted = cm2feet(value);
      const convertedMaxLength = feet2cm(maxLength);
      return [
        (v) =>
          !v || converted < maxLength || `Max: ${convertedMaxLength} ${unit}`
      ];
    }

    case LENGTH_UNITS.UNKNOWN:
    default:
      return [];
  }
};

export const getWeightLimitRules = (fish, value, unit) => {
  const maxWeight = FISH_MAX_LBS_WEIGHT[fish] || 0;

  if (!maxWeight || !value) {
    return [];
  }

  if (WEIGHT_UNITS.KG === unit) {
    const converted = kg2lbs(value);
    const convertedMaxWeight = lbs2kg(maxWeight);
    return [
      (v) => !v || converted < maxWeight || `Max: ${convertedMaxWeight} ${unit}`
    ];
  }

  if (WEIGHT_UNITS.LBS === unit) {
    return [(v) => !v || value < maxWeight || `Max: ${maxWeight} ${unit}`];
  }

  return [];
};

let trophies = [];
for (let index = 2; index <= 10000 / 500; index++) {
  trophies.push({ code: `${index * 500}th`, name: `${index * 500}th` });
}

export const MILESTONES = [
  {
    category: 'tag',
    name: 'TAGS',
    trophies: [
      { code: '1st', name: '1st' },
      { code: '10th', name: '10th' },
      { code: '25th', name: '25th' },
      { code: '50th', name: '50th' },
      { code: '75th', name: '75th' },
      { code: '100th', name: '100th' },
      { code: '250th', name: '250th' },
      { code: '500th', name: '500th' },
      { code: '750th', name: '750th' },
      ...trophies
    ]
  },
  {
    category: 'release',
    name: 'RELEASES',
    trophies: [
      { code: '1st', name: '1st' },
      { code: '10th', name: '10th' },
      { code: '25th', name: '25th' },
      { code: '50th', name: '50th' },
      { code: '75th', name: '75th' },
      { code: '100th', name: '100th' },
      { code: '250th', name: '250th' },
      { code: '500th', name: '500th' },
      { code: '750th', name: '750th' },
      ...trophies,
      { code: 'grandSlam', name: 'GRAND SLAM' }
      // { code: 'royalSlam', name: 'Royal Slam' },
    ]
  },
  {
    category: 'recapture',
    name: 'RECAPTURES',
    trophies: [
      { code: '1st', name: '1st' },
      { code: '5th', name: '5th' },
      { code: '10th', name: '10th' },
      { code: '25th', name: '25th' },
      { code: '50th', name: '50th' }
    ]
  }
];

export const MILESTONES_ROLES = [
  { code: 'angler', name: 'ANGLER' },
  { code: 'captain', name: 'CAPTAIN' },
  { code: 'firstMate', name: 'FIRST MATE' },
  { code: 'secondMate', name: 'SECOND MATE' }
];

export const RECORD_TYPES = ['Tags', 'Releases', 'Recaptures', 'Juveniles'];
export const SOURCE_TYPES = ['TBF']; //, 'BLUEFIN'

export const billfishDefaultImg = require('@/assets/billfish-default.png');
