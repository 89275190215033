import router from '../router/index';
import { loggedIn } from './user';

export const handleCall = async (method, api, path, params = null) => {
  try {
    let headers = {};
    const openRouteKeys = ['login', 'password', 'register', 'captcha', 'token'];
    const isOpenRoute = openRouteKeys.some((routeKey) =>
      String(path || '').includes(routeKey)
    );

    if (!isOpenRoute) {
      const token = loggedIn();
      headers = { ...headers, Authorization: token ? `Bearer ${token}` : null };
    }

    if (String(path || '').includes('images')) {
      headers = { ...headers, 'Content-Type': 'image/jpg' };
    }

    let response = null;
    if (!String(path).includes('undefined')) {
      switch (method) {
        case 'POST':
          response = await api.post(path, params, { headers });
          break;

        case 'PATCH':
          response = await api.patch(path, params, { headers });
          break;

        case 'DELETE':
          response = await api.delete(path, { headers });
          break;

        case 'GET':
          response = await api.get(path, { headers });
          break;

        default:
          break;
      }
    }
    return response;
  } catch (e) {
    if (String(e).includes('404') || String(e).includes('409')) {
      return e.response;
    }

    if (String(e).includes('401') || String(e).includes('403')) {
      localStorage.removeItem('data');
      router.push({ path: '/login' }).catch(() => {});
    }
    return null;
  }
};
