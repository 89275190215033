import axios from 'axios';

//Import http clients
import user from '@/api/httpClients/user';
import entry from '@/api/httpClients/entry';
import query from '@/api/httpClients/query';
import analytic from '@/api/httpClients/analytic';

//Import and initialize http client factory
import HttpClientFactory from '@/api/httpClientFactory';

//Initialize http client factory
const clientFactory = new HttpClientFactory(axios);

//Register one or more http client
clientFactory
  .add(user)
  .add(entry)
  .add(query)
  .add(analytic);

export default clientFactory;
