import { handleCall } from '../../helpers/handler';
class UserModule {
  constructor(httpClientFactory) {
    const userAPI = httpClientFactory.clients.user;

    this._store = {
      state: {
        data: {
          species: null
        }
      },
      getters: {},
      mutations: {},
      actions: {
        getUser({ state }, params) {
          const path = `/users/${params.id}`;
          return handleCall('GET', userAPI, path);
        },
        getUsers({ state }, params) {
          const { page, limit, name, status, pattern, sort, order, type } =
            params || {};
          let queryParams = `page=${page}&limit=${limit}`;
          name && (queryParams = queryParams.concat(`&name=${name}`));
          status && (queryParams = queryParams.concat(`&status=${status}`));
          pattern && (queryParams = queryParams.concat(`&pattern=${pattern}`));
          sort && (queryParams = queryParams.concat(`&sort=${sort}`));
          order && (queryParams = queryParams.concat(`&order=${order}`));
          type && (queryParams = queryParams.concat(`&type=${type}`));
          const path = `/users?${queryParams}`;
          return handleCall('GET', userAPI, path);
        },
        saveUserInfo({ state }, params = {}) {
          const { id, _id } = params;
          const path = `/users/${_id || id}`;
          return handleCall('PATCH', userAPI, path, params);
        },
        discardUser({ state }, params = {}) {
          const { id, _id } = params;
          const path = `/discard/${_id || id}`;
          return handleCall('GET', userAPI, path);
        },
        addUser({ state }, params) {
          const path = `/users/`;
          return handleCall('POST', userAPI, path, params);
        },
        login({ state }, data) {
          return handleCall('POST', userAPI, 'login', data);
        },
        forgotPwd({ state }, data) {
          return handleCall('POST', userAPI, 'forgot-password', data);
        },
        resetPwd({ state }, data) {
          return handleCall('POST', userAPI, 'reset-password', data);
        },
        getDuplicates({ state }, params) {
          const { page, limit, category, user } = params;
          const path = `duplicates/find?category=${category}&page=${page}&limit=${limit}`;
          return handleCall('POST', userAPI, path, user);
        },
        getProfiles({ state }, params) {
          const { body, category } = params;
          const path = `duplicates/retrieve?category=${category}`;
          return handleCall('POST', userAPI, path, body);
        },
        deleteUser({ state }, params) {
          const path = `/users/${params.id}`;
          return handleCall('DELETE', userAPI, path);
        },
        merge({ state }, params) {
          const { id, merge, mergedBy } = params;
          const path = `duplicates/merge?userId=${id}&mergedBy=${mergedBy}`;
          return handleCall('POST', userAPI, path, merge);
        },
        checkEmail({ state }, params) {
          const path = `/users?email=${params.email}`;
          return handleCall('GET', userAPI, path);
        },
        checkPersonalPhone({ state }, params) {
          const path = `/users?personalPhone=${params.personalPhone}`;
          return handleCall('GET', userAPI, path);
        },
        getVirtuous({ state }, params) {
          const { page, limit, name } = params || {};
          let queryParams = `page=${page}&limit=${limit}`;
          name && (queryParams = queryParams.concat(`&name=${name}`));
          const path = `/virtuous?${queryParams}`;
          return handleCall('GET', userAPI, path);
        },
        connectAccounts({ state }, params) {
          return handleCall('POST', userAPI, '/virtuous/connect', params);
        },
        disconnectAccounts({ state }, params) {
          const { userId, executedBy } = params || {};
          return handleCall('POST', userAPI, `/virtuous/disconnect/${userId}`, {
            executedBy
          });
        },
        hideContact({ state }, params) {
          return handleCall('POST', userAPI, '/virtuous/hide', params);
        },
        exposeContact({ state }, params) {
          return handleCall('POST', userAPI, '/virtuous/expose', params);
        },
        getExistentUsers({ state }, params = {}) {
          return handleCall('POST', userAPI, '/users/findUsers', params);
        }
      },
      namespaced: true
    };
  }

  get store() {
    return this._store;
  }
}

export default UserModule;
