import moment from 'moment';
import * as XLSX from 'xlsx';

export const formatStr = (str) => {
  return str === 'NULL' || str === 'UNKNOWN' ? '' : str;
};

export const formatDate = (value) => {
  const date = moment(value || null);
  return date.isValid() ? date.format('MM/DD/YYYY') : '';
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const getFile = (base64 = '', type = 'excel', title = '', name = '') => {
  if (!base64) {
    return;
  }

  const str = title?.length >= 20 ? title.split(' ')[0] : title;
  let filename = name || `${str} ${moment().format('MM.DD.YYYY')}`;

  const a = document.createElement('a');
  let headerFile;
  if (type === 'csv') {
    headerFile = 'data:attachment/csv';
    filename = filename.concat('.csv');
  } else if (type === 'excel' || type === 'xlsx') {
    headerFile = 'data:application/vnd.ms-excel';
    filename = filename.concat('.xlsx');
  }
  a.href = `${headerFile};base64, ${base64}`;
  a.download = filename;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getFileDeprecated = (
  items = [],
  cols = [],
  type = 'excel',
  title = '',
  name = ''
) => {
  if (!items.length || !cols.length) {
    return;
  }

  const str = title?.length >= 20 ? title.split(' ')[0] : title;
  const filename = name || `${str} ${moment().format('MM.DD.YYYY')}`;

  const workSheet = XLSX.utils.json_to_sheet(items || []);
  workSheet['!cols'] = cols || [];

  if (type === 'csv') {
    const csvString = XLSX.utils.sheet_to_csv(workSheet);
    const blob = new Blob([csvString], {
      type: 'data:attachment/csv;charset=utf-8,'
    });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.target = '_blank';
    a.download = `${filename}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else if (type === 'excel') {
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, filename);
    XLSX.writeFile(workBook, `${filename}.xlsx`);
  }
};

export const fixTimezone = (value) => {
  if (!value) {
    return null;
  }
  const date = new Date(value);
  const tzOffset = date.getTimezoneOffset();
  const momentDate = moment(date);
  if (!momentDate.isValid()) {
    return null;
  }
  // return momentDate.add('minutes', tzOffset);
  return momentDate.add(tzOffset, 'minutes');
};

// export const fixTimezone = date => {
//   const ISO_FORMAT_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)((-(\d{2}):(\d{2})|Z)?)$/;
//   if (ISO_FORMAT_REGEX.test(date)) {
//     return String(date).split('T')[0];
//   }
//   return date;
// };

export const getImg = (url = '', title = '') => {
  if (!url?.length) {
    return;
  }

  const filename = `${title}.jpg`;
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const formatPhone = (value) => {
  return (
    value
      ?.replace(/\D+/g, '')
      ?.replace(/(\d{3})(\d{3})(\d{1,4})/, '($1) $2-$3') || ''
  );
};

/**
 * Select a range of cells
 * @param  {string}   columnRange - Column Range ("A:B")
 * @return {Object[]}           - Selected cells
 */
export const selectSheetRange = (sheet, columnRange) => {
  const [start, end] = columnRange.split(':', 2);

  let endColumn = sheet.getColumn(end);
  let startColumn = sheet.getColumn(start);

  if (!endColumn) throw new Error('End column not found');
  if (!startColumn) throw new Error('Start column not found');

  endColumn = endColumn._number;
  startColumn = startColumn._number;
  const totalRows = sheet.actualRowCount;

  const data = [];
  for (let rowIndex = 1; rowIndex <= totalRows; rowIndex++) {
    const row = sheet.getRow(rowIndex);
    const rowData = [];

    for (let colIndex = startColumn; colIndex <= endColumn; colIndex++) {
      const cell = row.getCell(colIndex);
      rowData.push(cell.value);
    }

    data.push(rowData);
  }

  return data;
};
