import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuebar from 'vuebar';
import './plugins/base';
import VueSkycons from 'vue-skycons';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueMask from 'v-mask';
Vue.use(VueMask);

Vue.use(require('vue-moment'));

Vue.use(VueSkycons, {
  color: '#1e88e5'
});
Vue.use(Vuebar);
Vue.use(VueSweetalert2);

//Import application store;
import store from '@/stores';

Vue.config.productionTip = false;
new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');
