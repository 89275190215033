import { getUserInfo, handleCall } from '../../helpers/handler';
class EntryModule {
  constructor(httpClientFactory) {
    const entryAPI = httpClientFactory.clients.entry;

    this._store = {
      state: {},
      getters: {},
      mutations: {},
      actions: {
        addTag({ state }, params) {
          const user = getUserInfo();
          const path = `tags?userId=${user?.id}`;
          return handleCall('POST', entryAPI, path, params);
        },
        addRecapture({ state }, params) {
          const user = getUserInfo();
          const path = `recaptures?userId=${user?.id}`;
          return handleCall('POST', entryAPI, path, params);
        },
        addRelease({ state }, params) {
          const user = getUserInfo();
          const path = `releases?userId=${user?.id}`;
          return handleCall('POST', entryAPI, path, params);
        },
        editTag({ state }, params) {
          const { _id, id } = params;
          const path = `tags/${_id || id}`;
          return handleCall('PATCH', entryAPI, path, params);
        },
        editRecapture({ state }, params) {
          const { _id, id } = params;
          const path = `recaptures/${_id || id}`;
          return handleCall('PATCH', entryAPI, path, params);
        },
        editRelease({ state }, params) {
          const { _id, id } = params;
          const path = `releases/${_id || id}`;
          return handleCall('PATCH', entryAPI, path, params);
        },
        getJuvenile({ state }, params) {
          let path = 'juvenile-billfish';
          const { id } = params;
          if (id) {
            path = path.concat(`?id=${id}`);
          }
          return handleCall('GET', entryAPI, path);
        },
        addJuvenile({ state }, params) {
          const user = getUserInfo();
          const path = `juvenile-billfish`;
          return handleCall('POST', entryAPI, path, params);
        },
        editJuvenile({ state }, params) {
          const { id, _id } = params;
          const path = `juvenile-billfish/${_id || id}`;
          return handleCall('PATCH', entryAPI, path, params);
        },
        deleteJuvenile({ state }, params) {
          const { id, _id } = params;
          const path = `juvenile-billfish/${_id || id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        addItem({ state }, params) {
          const { item, data } = params;
          delete data._id;
          return handleCall('POST', entryAPI, item, data);
        },
        editItem({ state }, params) {
          const { item, data } = params;
          const path = `${item}/${data._id}`;
          delete data._id;
          return handleCall('PATCH', entryAPI, path, data);
        },
        deleteItem({ state }, params) {
          const path = `${params.item}/${params.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        checkEvent({ state }, params) {
          const user = getUserInfo();
          const path = `${params.event}/check?userId=${user?.id}`;
          return handleCall('POST', entryAPI, path, params.payload);
        },
        saveIssue({ state }, params) {
          return handleCall('POST', entryAPI, 'tags-issue', params);
        },
        editIssue({ state }, params) {
          const path = `tags-issue/${params._id}`;
          return handleCall('PATCH', entryAPI, path, params);
        },
        editIssues({ state }, params) {
          return handleCall('POST', entryAPI, 'tags-issues/edit', params);
        },
        deleteIssue({ state }, params) {
          const path = `tags-issue/${params.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        deleteIssues({ state }, params) {
          return handleCall('POST', entryAPI, 'tags-issues/delete', params);
        },
        getAvatar({ state }, params = {}) {
          const user = getUserInfo();
          const path = `images/${user?.id}`;
          return handleCall('GET', entryAPI, path);
        },
        uploadAvatar({ state }, params = {}) {
          const user = getUserInfo();
          const payload = {
            filename: user?.id,
            image: params
          };
          return handleCall('POST', entryAPI, 'images', payload);
        },
        deleteAvatar({ state }, params) {
          const user = getUserInfo();
          const path = `images/${user?.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        deleteTag({ state }, params) {
          const path = `/tags/${params.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        deleteRelease({ state }, params) {
          const path = `/releases/${params.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        deleteRecapture({ state }, params) {
          const path = `/recaptures/${params.id}`;
          return handleCall('DELETE', entryAPI, path);
        },
        saveLocation({ state }, params = {}) {
          return handleCall('POST', entryAPI, `locations`, params);
        },
        sendEmail({ state }, params = {}) {
          return handleCall('POST', entryAPI, 'send-email', params);
        },
        getFishPicture({ state }, params = {}) {
          const path = `images/${params?.id}`;
          return handleCall('GET', entryAPI, path);
        },
        uploadFishPicture({ state }, params = {}) {
          const { id, image } = params;
          const payload = { filename: id, image };
          return handleCall('POST', entryAPI, 'images', payload);
        },
        submitReward({ state }, params = {}) {
          return handleCall('POST', entryAPI, 'reward-settings', params);
        }
      },
      namespaced: true
    };
  }
  get store() {
    return this._store;
  }
}

export default EntryModule;
