import Vue from 'vue';

export const notifySuccess = (text = '', title = 'Successfully submitted') => {
  Vue.swal({ icon: 'success', title, text });
};

export const notifyError = (text = '', title = 'An error ocurred') => {
  Vue.swal({ icon: 'error', title, text });
};

export const notifyMessage = (title = '', timer = 3000) => {
  Vue.swal({
    icon: 'info',
    title,
    position: 'bottom-right',
    timer,
    timerProgressBar: true,
    toast: true,
    showConfirmButton: false
  });
};

export const notifyConfirmation = (
  text = "You won't be able to revert this!",
  title = 'Are you sure?',
  onConfirm = () => {},
  onCancel = () => {},
  confirmButtonText = 'Ok',
  cancelButtonText = 'Cancel',
  allowOutsideClick = true,
  showCancelButton = true
) => {
  Vue.swal({
    icon: 'warning',
    title,
    text,
    showCancelButton,
    confirmButtonText,
    cancelButtonText,
    allowOutsideClick
  }).then((result) => {
    if (result.isConfirmed) {
      onConfirm();
    }

    if (result.isDismissed) {
      onCancel();
    }
  });
};
