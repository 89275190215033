export const handleCoords = (coords) => {
  if (!coords) {
    return null;
  }

  const latitude = convertDDM2DD(coords.latitude);
  const longitude = convertDDM2DD(coords.longitude);

  if (!latitude || !longitude) {
    return null;
  }

  return [latitude, longitude];
};

export const convertDDM2DD = (coordinate) => {
  const { degrees, minutes, hemisphere } = coordinate || {};

  if ((!degrees && degrees !== 0) || isNaN(degrees)) {
    return null;
  }

  if ((!minutes && minutes !== 0) || isNaN(minutes)) {
    return null;
  }

  if (!hemisphere) {
    return null;
  }

  return (
    (Number(degrees) + Number(minutes) / 60) *
    (['S', 'W'].includes(hemisphere.toUpperCase()) ? -1 : 1)
  );
};

export const reverseCoords = (coords) => {
  const { lat, lng } = coords;
  const latitude = convertDD2DDM(lat, 90, ['N', 'S']);
  const longitude = convertDD2DDM(lng, 180, ['E', 'W']);
  return { latitude, longitude };
};

export const convertDD2DDM = (value, limit, hemispheres) => {
  const integer = Math.trunc(value);
  const rate = integer / limit;
  const co_terminal = integer - limit * Math.trunc(rate);
  const degree = Math.abs(rate) >= 1 ? co_terminal : integer;
  const option = Math.abs(rate) >= 1 && Math.trunc(rate) % 2;
  return {
    degrees: !option ? Math.abs(degree) : 180 - Math.abs(degree),
    minutes: Math.abs(value - integer) * 60,
    hemisphere: getHemisphere(value, rate, hemispheres)
  };
};

const getHemisphere = (value, rate, hemispheres) => {
  const ref = value >= 0 ? 0 : 1;
  const option = Math.abs(rate) >= 1 && Math.trunc(rate) % 2;
  return !option ? hemispheres[ref] : hemispheres[ref ? 0 : 1];
};

export const coordsPatterns = {
  lat: {
    isDMS:
      /^(([1-8]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|90\D+0\D+0)\D+[NSns]$/,
    isDDM: /^(([1-8]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|90(\D+0)?)\D+([NSns])$/,
    isDD: /^[\+-]?(([1-8]?[0-9])(\.\d{1,6})?|90)\D*[NSns]?$/
  },
  lng: {
    isDMS:
      /^((1[0-7][0-9]|[1-9]?[0-9])\D+([1-5]?[0-9]|60)\D+([1-5]?[0-9]|60)(\.[0-9]+)?|180\D+0\D+0)\D+[EWew]$/,
    isDDM:
      /^((1[0-7][0-9]|[1-9]?[0-9])\D+[1-6]?[0-9](\.\d{1,3})?|180(\D+0)?)\D+([EWew])$/,
    isDD: /^[\+-]?((1[0-7][0-9]|[1-9]?[0-9])(\.\d{1,6})?|180)\D*[EWew]?$/
  }
};

export const getMarkerContent = (data) => `
<b>Angler: </b> ${data?.angler?.firstName || ''} ${
  data?.angler?.lastName || ''
} <br />
<b>Captain: </b> ${data?.captain?.firstName || ''} ${
  data?.captain?.lastName || ''
} <br />
<b>First Mate: </b> ${data?.firstMate?.firstName || ''} ${
  data?.firstMate?.lastName || ''
} <br />
<b>Ocean: </b> ${data?.capture?.ocean || ''} <br />
<b>Event: </b> ${data?.capture?.tournament?.name || ''} <br />
<b>Bait: </b> ${data?.capture?.bait?.name || ''} <br />
<b>Fish's Species: </b> ${data?.capture?.fish?.specie?.name} <br />
<b>Fish's Weight: </b> ${data?.capture?.fish?.weight?.value || 0} lb <br />
<b>Fish's Length: </b> ${data?.capture?.fish?.length?.value || 0} ft
`;

export const markerIconImg = require('@/assets/marker-icon-2x-orange.png');
export const markerShadowImg = require('@/assets/marker-shadow.png');

export const validCoordinates = (coordinates) => {
  if (!coordinates) {
    return false;
  }

  const { latitude, longitude } = coordinates || {};

  if (!latitude || !longitude) {
    return false;
  }

  const noLat = Object.values(latitude).some((v) => v === null);
  const noLng = Object.values(longitude).some((v) => v === null);

  if (noLat || noLng) {
    return false;
  }

  return true;
};
