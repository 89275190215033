//Import vue components
import Vue from 'vue';
import Vuex from 'vuex';

//Initialize vuex plugins
Vue.use(Vuex);

//Import module dependencies
import clientFactory from '@/api';

//Import store modules
import UserModule from '@/stores/modules/users';
import SideBarModule from '@/stores/modules/sidebar';
import QueryModule from '@/stores/modules/query';
import  EntryModule from '@/stores/modules/entry';
import  AnalyticModule from '@/stores/modules/analytic';

//Initialize store modules
const users = new UserModule(clientFactory).store;
const sidebar = new SideBarModule(clientFactory).store;
const query = new QueryModule(clientFactory).store;
const entry = new EntryModule(clientFactory).store;
const analytic = new AnalyticModule(clientFactory).store;

export default new Vuex.Store({
    modules: {
        users,
        sidebar,
        query,
        entry,
        analytic
    }
});